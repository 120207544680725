main();

function main() {
    // Video stuff
    var playButton = document.getElementById("play-video");
    playButton.addEventListener("click", openModal);

    // UTM stuff
    var q = window.location.search
        .substring(1)
        .split("&")
        .reduce(function reduction(a, b) {
            var split = b.split("=");
            a[split[0]] = split[1];

            return a;
        }, {});

    if (q["utm"]) {
        var androidLink = document.getElementById("android-link");
        var iOsLink = document.getElementById("ios-link");

        androidLink.addEventListener(
            "click",
            linkHandler.bind(null, "android", q["utm"], androidLink.href),
            false,
        );
        iOsLink.addEventListener(
            "click",
            linkHandler.bind(null, "ios", q["utm"], iOsLink.href),
            false,
        );
    }
}

function linkHandler(platform, utm, dest, event) {
    event.stopPropagation();
    event.preventDefault();

    window.location.href =
        "https://api.lionfi.sh/count?utm=" +
        utm +
        "&d=" +
        btoa(dest) +
        "&p=" +
        platform;

    return false;
}

function openModal() {
    var modal = document.getElementById("video-modal").cloneNode(true);

    modal.style.width = window.innerWidth + "px";
    modal.style.height = window.innerHeight + "px";
    modal.style.position = "fixed";
    modal.style.top = "0";
    modal.style.left = "0";
    modal.hidden = false;

    modal.addEventListener("click", function (e) {
        console.log("e.target:", e.target);

        if (e.target.tagName !== "VIDEO") {
            document.body.removeChild(modal);
        }
    });

    document.body.appendChild(modal);
}
